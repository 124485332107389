import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ChallengeTypes, IRequester, IStep } from './i-challenge';

@Injectable()
export class SignStore {
  // @ts-ignore
  readonly currentStep$ = new BehaviorSubject<IStep>(null);

  // @ts-ignore
  readonly requester$ = new BehaviorSubject<IRequester>(null);

  // @ts-ignore
  readonly connectionName$ = new BehaviorSubject<string>(null);

  readonly context$ = new BehaviorSubject<string | null>(null);

  readonly initialChallengeType$ = new BehaviorSubject<ChallengeTypes | null>(null);

  readonly fido2Enroll$ = new BehaviorSubject<boolean>(false);

  readonly animateSuccess$ = new BehaviorSubject<boolean>(false);

  readonly animateOnSuccess$ = new BehaviorSubject<boolean>(false);

  readonly dataWasAccepted$ = new BehaviorSubject<boolean | undefined>(undefined);

  readonly resetFlow$ = new BehaviorSubject<boolean | undefined>(undefined);

  readonly fidoTriggered$ = new BehaviorSubject<boolean>(false);

  readonly loginId$ = new BehaviorSubject<string | null>(null);
}
