import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { datadogLogs } from '@datadog/browser-logs';
import LogRocket from 'logrocket';

if (environment.production) {
  enableProdMode();
}

datadogLogs.init({
  clientToken: 'pub459b1bf250af80cb955b40e3e7d9935c',
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  sampleRate: 100,
  env: environment.envName,
  service: 'web-app',
  beforeSend: (event) => {
    if (!event.context && event.status === 'error') {
      event.status = 'warn';
    }
  },
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // eslint-disable-next-line unicorn/prefer-top-level-await
  .catch((error) => console.error(error));

function getChallengeFromUrl() {
  const url = location.href;
  const decodedUrl = decodeURIComponent(url);
  const contextRegex = /.+ownid\/([\dA-z\-]+)\/.+/g;
  const match = contextRegex.exec(decodedUrl);

  if (match) {
    const [, challenge] = match;
    return challenge;
  }

  return null;
}

if (environment.logRocket) {
  const logRocketEnv = environment.logRocket[window.location.host];
  if (logRocketEnv) {
    LogRocket.init(logRocketEnv);
    const context = getChallengeFromUrl();
    if (context) LogRocket.identify(context);
  }
}
