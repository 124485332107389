import { IEnvironment } from './i-environment';

export const environment: IEnvironment = {
	production: true,
	baseURL: '/api/v1/',
	logLevel: "information",
	logType: "backend",
	salt: 'QTZ4blFoYno0VngySHVHbDRsWHdaNVUySThpemlMUkZuaFA1ZU5mSVJ2UQ',
	serverURL: '',
	maxProfileFieldsCount: 50,
	envName: 'dev2',
	translationsUrlPrefix: 'https://i18n.dev2.ownid-eu.com/',
	translationsUrlSuffix: '/web-app.json',
	allowMocks: false,
};
